@charset "UTF-8";
/*
  little-innovator sass Them
  version:0.0.1
*/
/*Direction */
/* BASE - Base styles , variables, mixins, ect*/
@supports (-webkit-touch-callout: none) {
  html {
    overflow-x: hidden;
  }
}

body {
  font-family: 'Tajawal', sans-serif;
  background-image: url(../assets/img/blur-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: #FBFCFE;
  font-size: 1.25rem;
  overflow-x: hidden;
}

main {
  min-height: calc(100vh - 142px);
}

.container-fluid {
  max-width: 1440px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

img {
  max-width: 100%;
}

.border-right {
  border-right-width: 0 !important;
  border-left: 1px solid #dee2e6 !important;
}

.border-left {
  border-left-width: 0 !important;
  border-right: 1px solid #dee2e6 !important;
}

a {
  text-decoration: none;
}

/*MODULES  - Individual site components */
.theme-heading-color {
  background: linear-gradient(269.81deg, #600627 20.45%, #42A347 99.77%);
  line-height: 1.5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: calc(1.4375rem + 2.25vw);
}

@media (min-width: 1200px) {
  .theme-heading-color {
    font-size: 3.125rem;
  }
}

.fs-7 {
  font-size: 0.9375rem;
}

.timeline::after {
  content: " ";
  position: absolute;
  top: 10px;
  right: calc(25% + 3.4rem);
  width: 1px;
  height: calc(100% - 30px);
  background-color: #7B1139;
}

@media (min-width: 992px) {
  .timeline .right {
    width: 75%;
  }
}

.timeline .right::before {
  content: " ";
  width: 17px;
  height: 17px;
  background: #f8fafb;
  border: 2px solid #971B49;
  position: absolute;
  top: 2px;
  right: -7px;
  border-radius: 50rem;
  z-index: 1;
}

.timeline .right.active::before {
  width: 22px;
  height: 22px;
  background-color: #971B49;
  border-color: #fff;
  border-width: 3px;
}

@media (max-width: 991.98px) {
  .timeline .right.active::before {
    right: -25px;
  }
}

@media (min-width: 992px) {
  .timeline .left {
    width: 25%;
  }
}

@media (max-width: 991.98px) {
  .timeline:after {
    right: 9px;
  }
  .timeline .right::before {
    top: -30px;
    right: -23px;
  }
}

.timeline.aside {
  right: 15px;
  -webkit-padding-start: 1.75rem;
          padding-inline-start: 1.75rem;
}

.timeline.aside:after {
  right: 0;
}

.timeline.aside .left {
  width: 100%;
}

.timeline.aside .right::before {
  top: -23px;
  right: -36px;
}

.timeline.aside .right.active::before {
  right: -39px;
  border-width: 4px;
}

.timeline.aside label {
  font-size: 0.875rem;
  color: #6c757d;
}

.timeline.aside h5 {
  color: #000;
  line-height: 1.5;
}

.btn-link {
  color: #42A347;
  text-decoration: none;
  font-weight: 500;
  font-size: calc(1.2625rem + 0.15vw);
}

@media (min-width: 1200px) {
  .btn-link {
    font-size: 1.375rem;
  }
}

.btn-green {
  background-color: #5ACA60;
}

.btn-green:hover {
  background-color: #42A347;
}

.btn-lg {
  min-height: 70px;
}

.btn-simple {
  color: #971B49;
  border-color: #971B49;
  border-width: 3px;
}

.btn-simple:hover {
  color: #7B1139;
  border-color: #7B1139;
  background: rgba(151, 27, 73, 0.04);
}

.btn.submit {
  min-height: 65px;
  font-size: 1.25rem;
  width: 100%;
}

@media (min-width: 992px) {
  .btn.submit {
    min-width: 450px;
  }
}

.btn-primary-light {
  font-weight: 500;
  background: rgba(151, 27, 73, 0.1);
}

.dropdown-menu[data-bs-popper] {
  left: auto;
  right: 0;
  text-align: right;
}

.dropdown-menu[data-bs-popper] a.dropdown-item {
  color: #971B49;
  font-size: 1.0625rem;
  font-weight: 500;
}

.dropdown-menu[data-bs-popper] a.dropdown-item:hover, .dropdown-menu[data-bs-popper] a.dropdown-item:focus {
  background-color: #fceff4;
  color: #6c1334;
}

@font-face {
  font-family: "little-innovator";
  src: url("../assets/icons/little-innovator.eot");
  src: url("../assets/icons/little-innovator.eot?#iefix") format("embedded-opentype"), url("../assets/icons/little-innovator.woff") format("woff"), url("../assets/icons/little-innovator.ttf") format("truetype"), url("../assets/icons/little-innovator.svg#little-innovator") format("svg");
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  position: relative;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "little-innovator" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
  content: "\62";
}

.icon-prize:before {
  content: "\65";
}

.icon-calender:before {
  content: "\67";
}

.icon-conditions:before {
  content: "\69";
}

.icon-faq:before {
  content: "\6b";
}

.icon-literature:before {
  content: "\6e";
}

.icon-music:before {
  content: "\6f";
}

.icon-network:before {
  content: "\70";
}

.icon-upload:before {
  content: "\71";
}

.icon-view:before {
  content: "\72";
}

.icon-arrow-down:before {
  content: "\61";
}

.icon-nav:before {
  content: "\64";
}

.icon-art:before {
  content: "\63";
}

.icon-sing:before {
  content: "\66";
}

.icon-user:before {
  content: "\68";
}

.icon-doc:before {
  content: "\6a";
}

.icon-vedio:before {
  content: "\6c";
}

.box-shadow, .timeline .right.active::before {
  -webkit-box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.02);
          box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.02);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.list-style li {
  position: relative;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 0 !important;
}

.list-style li:after {
  content: " ";
  width: 1px;
  height: calc(100% - 10px);
  position: absolute;
  top: 5px;
  left: 0;
  background-color: #bb2e62;
  opacity: 0.5;
}

.list-style li:first-child {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.list-style li:last-child {
  -webkit-padding-end: 0;
          padding-inline-end: 0;
}

.list-style li:last-child:after {
  display: none;
}

.accordion-item + .accordion-item {
  border-top: 4px solid #edf5f6;
}

.accordion-item .accordion-button {
  font-weight: 500;
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  .accordion-item .accordion-button {
    font-size: 1.875rem;
  }
}

.accordion-item .accordion-button::after {
  margin-left: unset;
  margin-right: auto;
}

.accordion-item .btn-primary {
  font-weight: 500;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.arrow-list-style li {
  background-image: url(../assets/img/arrow-link.svg);
  background-repeat: no-repeat;
  background-position: right 8px;
  padding-right: 17px;
}

.form-floating > label {
  left: auto;
  right: 0;
  font-size: 0.9375rem;
}

.form-floating {
  margin-bottom: 1.25rem;
}

.form-floating > .form-control:focus::-webkit-input-placeholder, .form-floating > .form-control:not(:placeholder-shown)::-webkit-input-placeholder {
  color: #6c757d;
  font-size: 15px;
}

.form-floating > .form-control:focus:-ms-input-placeholder, .form-floating > .form-control:not(:placeholder-shown):-ms-input-placeholder {
  color: #6c757d;
  font-size: 15px;
}

.form-floating > .form-control:focus::-ms-input-placeholder, .form-floating > .form-control:not(:placeholder-shown)::-ms-input-placeholder {
  color: #6c757d;
  font-size: 15px;
}

.form-floating > .form-control:focus::placeholder, .form-floating > .form-control:not(:placeholder-shown)::placeholder {
  color: #6c757d;
  font-size: 15px;
}

.divider-lg {
  background-color: #EDF5F6;
  height: 4px;
  margin-left: -1rem;
  margin-right: -1rem;
}

form h6 {
  color: #000;
  font-size: 1.0625rem;
}

@media (min-width: 992px) {
  form.register-form {
    max-width: 450px;
  }
}

.form-text {
  font-size: 0.875rem;
}

.modal-header .btn-close {
  margin-left: -0.5rem;
  margin-right: auto;
}

.form-check {
  padding-right: 1.5em;
  padding-left: 0;
}

.form-check .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-select {
  padding-right: 0.95rem;
  padding-left: 2.85rem;
  background-position: left 0.95rem center;
}

@media (min-width: 992px) {
  .max-form-row {
    max-width: 585px;
  }
}

.form-control[type="file"] {
  width: 72px;
  height: 72px;
  background-color: #f5e8ed;
  border-radius: 20px;
  padding: 20px;
  border-width: 0;
  color: transparent;
  text-indent: -9999px;
}

.form-control[type="file"]::before {
  text-indent: 0;
  position: absolute;
  font-size: 41px;
  background: -webkit-gradient(linear, left top, left bottom, from(#5ACA60), to(#7B1139));
  background: linear-gradient(180deg, #5ACA60 0%, #7B1139 100%);
  line-height: 1.5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
}

ol li {
  line-height: 1.5;
  margin-bottom: 0.75rem;
}

.bg-primary-light {
  background-color: #FCF5F8;
}

.winners-acc .accordion-item:hover {
  background-color: #f8f9fa;
}

.tags {
  background: #F5F6FD;
  color: #252A26;
  cursor: pointer;
  font-weight: 500;
}

.tags.active {
  background: #FBE8EC;
  color: #7B1139;
  cursor: default;
}

.artical-thumb {
  height: 184px;
}

.artical-thumb img {
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.artical-title {
  line-height: 1.5;
  height: 4rem;
  overflow: hidden;
  font-weight: 500;
}

.page-item:first-child .page-link {
  border-top-right-radius: 0.9375rem;
  border-bottom-right-radius: 0.9375rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
  border-top-left-radius: 0.9375rem;
  border-bottom-left-radius: 0.9375rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* LAYOUTS - page layout styles */
.navbar {
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.navbar .nav-link {
  font-size: 1.0625rem;
  font-weight: 500;
}

.navbar .btn-primary {
  font-size: calc(1.2625rem + 0.15vw);
  font-weight: 500;
}

@media (min-width: 1200px) {
  .navbar .btn-primary {
    font-size: 1.375rem;
  }
}

.navbar .dropdown-toggle:after {
  color: #F7CADB;
  vertical-align: 0.1em;
}

.navbar .navbar-brand img {
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

.navbar .navbar-brand img.ministry-header-logo {
  height: 60px;
  width: auto;
}

.navbar .navbar-brand img.main-logo {
  width: 120px;
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
}

.navbar.scroll {
  background-color: rgba(255, 255, 255, 0.65);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}

.navbar.scroll .navbar-brand img.main-logo {
  width: 90px;
}

.navbar.scroll .navbar-brand img.ministry-header-logo {
  height: 45px;
  width: auto;
}

.navbar .icon-user {
  color: #42A347;
  font-size: 1rem;
  height: 16px;
}

.navbar .dropdown-toggle.text-dark::after {
  color: #ced4da;
}

.navbar-toggler {
  color: #971B49 !important;
  outline: none;
}

.navbar-toggler span {
  height: 20px;
}

.navbar-toggler:focus {
  color: #7B1139;
}

.back-link .icon-arrow-left {
  height: 7px;
  font-size: calc(1.3125rem + 0.75vw);
  display: inline-block;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .back-link .icon-arrow-left {
    font-size: 1.875rem;
  }
}

.back-link .icon-arrow-left::before {
  position: relative;
  top: -11px;
}

.copy-right {
  opacity: 0.5;
}

.seperator {
  border-top: 1px solid #5B102C !important;
}

.social-list li {
  font-size: 0.9375rem;
  font-weight: 500;
}

.social-list li img {
  opacity: 0.5;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.social-list li .phone {
  border: 1px solid #E0BBC8;
  border-radius: 7px;
  padding: 5px 8px;
  height: 35px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.social-list li .phone > img {
  height: 20px;
  width: 20px;
}

.social-list li:hover img {
  opacity: 1;
}

.social-list li:hover .phone {
  border-color: #fff;
}

/* STATE - page of site*/
@media (min-width: 1200px) {
  .img-grid img {
    -webkit-filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.1));
  }
}

.col-top-nav {
  margin-top: -70px;
}

.patronage > div {
  font-size: 1.25rem;
  font-weight: 500;
}

.patronage:after {
  content: " ";
  height: 3px;
  top: 239px;
  width: 100%;
  position: absolute;
  top: 100%;
  right: 0;
  background: -webkit-gradient(linear, left top, right top, from(#7b1139), to(#42a347));
  background: linear-gradient(90deg, #7b1139 0%, #42a347 100%);
}

.prize-summary h1 {
  font-size: calc(1.34375rem + 1.125vw);
}

@media (min-width: 1200px) {
  .prize-summary h1 {
    font-size: 2.1875rem;
  }
}

.prize-summary h5 {
  font-weight: 700;
  font-size: calc(1.31875rem + 0.825vw);
  line-height: 1.5;
  color: #7B1139;
}

@media (min-width: 1200px) {
  .prize-summary h5 {
    font-size: 1.9375rem;
  }
}

.prize-summary p {
  font-weight: 500;
  font-size: 1.0625rem;
  max-width: 500px;
  color: #495057;
}

.prize-summary .btn {
  height: 50px;
  min-width: 300px;
  font-size: 1.25rem;
}

.prize-amount div {
  font-weight: 800;
  font-size: calc(1.5625rem + 3.75vw);
  line-height: 0.9;
  line-height: 1;
  letter-spacing: -0.03em;
  background: -webkit-gradient(linear, left top, right top, color-stop(41.68%, #7b1139), color-stop(106.73%, #5aca60));
  background: linear-gradient(90deg, #7b1139 41.68%, #5aca60 106.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 1200px) {
  .prize-amount div {
    font-size: 4.375rem;
  }
}

.prize-amount label {
  color: #971B49;
  font-size: 0.8125rem;
  bottom: 6px;
}

.prize-amount .sec {
  padding: 7PX 10px;
  border-radius: 0px 15px 15px 0px;
  line-height: 1;
  bottom: 6px;
}

.sponser-logo li {
  min-width: 100px;
  background: rgba(255, 255, 255, 0.3);
  padding: 12px;
}

.sponser-logo li img {
  width: 100;
  height: auto;
}

.sponser-logo li::before {
  content: "شريك رئيسي";
  position: absolute;
  top: -20px;
  right: 0;
  background: #f5c6d8;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  color: #971B49;
  border-radius: 0.5em 0.5em 0 0.6em;
  font-size: 1rem;
}

.cat-box {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 5px solid transparent;
}

.cat-box i {
  background: -webkit-gradient(linear, left top, left bottom, from(#42a347), to(#7b1139));
  background: linear-gradient(180deg, #42a347 0%, #7b1139 100%);
  line-height: 1.5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 0.7;
}

.cat-box i.icon-literature {
  font-size: calc(1.4375rem + 2.25vw);
  height: 50px;
}

@media (min-width: 1200px) {
  .cat-box i.icon-literature {
    font-size: 3.125rem;
  }
}

.cat-box i.icon-music {
  font-size: calc(1.35625rem + 1.275vw);
  height: 37px;
}

@media (min-width: 1200px) {
  .cat-box i.icon-music {
    font-size: 2.3125rem;
  }
}

.cat-box i.icon-sing {
  font-size: calc(1.39375rem + 1.725vw);
  height: 43px;
}

@media (min-width: 1200px) {
  .cat-box i.icon-sing {
    font-size: 2.6875rem;
  }
}

.cat-box i.icon-art {
  font-size: calc(1.33125rem + 0.975vw);
  height: 33px;
}

@media (min-width: 1200px) {
  .cat-box i.icon-art {
    font-size: 2.0625rem;
  }
}

.cat-box i.icon-network {
  font-size: calc(1.41875rem + 2.025vw);
  height: 47px;
}

@media (min-width: 1200px) {
  .cat-box i.icon-network {
    font-size: 2.9375rem;
  }
}

.cat-box h3 {
  font-size: calc(1.28125rem + 0.375vw);
  color: #971B49;
}

@media (min-width: 1200px) {
  .cat-box h3 {
    font-size: 1.5625rem;
  }
}

.cat-box li {
  font-size: 1.0625rem;
  color: #212529;
}

.cat-box:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #cfecd1;
}

.steps {
  background-color: #5e0828;
}

.steps .bg-blur {
  background: linear-gradient(94.63deg, #7b1139 18.64%, rgba(90, 202, 96, 0.6) 47.77%, #971b49 84.55%);
  -webkit-filter: blur(150px);
          filter: blur(150px);
}

.how-to ul {
  max-width: 885px;
}

.how-to h3 {
  font-size: calc(1.28125rem + 0.375vw);
}

@media (min-width: 1200px) {
  .how-to h3 {
    font-size: 1.5625rem;
  }
}

.how-to .rounded-circle {
  width: 131px;
  height: 131px;
  position: relative;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .how-to .rounded-circle {
    min-width: 70px;
    height: 70px;
    width: 70px;
  }
}

@media (min-width: 1200px) {
  .how-to .rounded-circle + p {
    max-width: 178px;
  }
}

.how-to strong {
  font-weight: 500;
  font-size: calc(1.375rem + 1.5vw);
  display: block;
}

@media (min-width: 1200px) {
  .how-to strong {
    font-size: 2.5rem;
  }
}

@media (min-width: 992px) {
  .how-to li {
    width: 33.33333333333333%;
  }
}

.how-to li:not(:last-child)::after {
  content: " ";
  width: calc(70% - 140px);
  height: 1px;
  background-color: #fff;
  left: 0;
  top: 65px;
  position: absolute;
}

@media (max-width: 991.98px) {
  .how-to li:not(:last-child)::after {
    display: none;
  }
}

.how-to li:not(:first-child)::before {
  content: " ";
  width: calc(70% - 140px);
  height: 1px;
  background-color: #fff;
  right: 0;
  top: 65px;
  position: absolute;
}

@media (max-width: 991.98px) {
  .how-to li:not(:first-child)::before {
    display: none;
  }
}

.how-to li:last-child .rounded-circle i {
  right: -17px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  font-size: 7px;
}

@media (max-width: 991.98px) {
  .how-to li:last-child .rounded-circle i {
    display: none;
  }
}

.how-to li:nth-child(2) .rounded-circle i {
  right: -17px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  font-size: 7px;
}

@media (max-width: 991.98px) {
  .how-to li:nth-child(2) .rounded-circle i {
    display: none;
  }
}

.timeline-bg {
  background-image: url(../assets/img/timeline-bg.jpg);
  background-position: top left;
  background-repeat: no-repeat;
  min-height: 700px;
  background-color: #f8fafb;
}

.container-date h5 {
  color: #971B49;
}

.help-links p {
  font-weight: 500;
}

.help-links .media {
  padding: 1.5rem;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  background: linear-gradient(213.36deg, #7B1139 0%, #42A347 167.68%) #7B1139;
}

@media (min-width: 992px) {
  .help-links .media {
    width: 119px;
    height: 150px;
    padding: 0;
  }
}

.help-links .media i {
  line-height: 0.7;
  font-size: calc(1.5125rem + 3.15vw);
}

@media (min-width: 1200px) {
  .help-links .media i {
    font-size: 3.875rem;
  }
}

.help-links li:hover .media {
  background-image: none;
}

.card-img {
  max-width: 260px;
}

.count-down {
  display: block;
  margin-top: 1rem;
}

.count-down > span {
  font-size: 50px;
  line-height: 1;
  color: #fff;
  font-weight: 800;
}

.count-down > span .item {
  position: relative;
}

.count-down > span .item + .item {
  -webkit-padding-start: 3rem;
          padding-inline-start: 3rem;
}

.count-down > span .item + .item::after {
  content: " ";
  width: 7px;
  height: 3px;
  position: absolute;
  background-color: #fff;
  top: 50%;
  right: calc(1.5rem - 5px);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.count-down > span .item::before {
  color: #5ACA60;
  font-size: 1.25rem;
  position: absolute;
  font-weight: 500;
  bottom: -10px;
  right: 35px;
}

.count-down > span .item:first-child::before {
  content: "يوم";
  right: 13px;
}

.count-down > span .item:nth-child(2)::before {
  content: "ساعه";
}

.count-down > span .item:nth-child(3)::before {
  content: "دقيقة";
}

.count-down > span .item:last-child::before {
  right: 60px;
  content: "ثانيه";
}

.manger-main {
  background: #6b042a;
  mix-blend-mode: multiply;
}

.manger-img {
  -webkit-filter: drop-shadow(0px 30px 50px rgba(123, 17, 57, 0.3));
          filter: drop-shadow(0px 30px 50px rgba(123, 17, 57, 0.3));
}

@media (min-width: 992px) {
  .manger-img img {
    width: 260px;
  }
}

.manger-pref h3 {
  font-size: calc(1.3125rem + 0.75vw);
  margin-bottom: 13px;
  font-weight: 400;
}

@media (min-width: 1200px) {
  .manger-pref h3 {
    font-size: 1.875rem;
  }
}

.manger-pref h6 {
  font-weight: 400;
}

.manger-pref p {
  font-size: 1.0625rem;
  opacity: 0.8;
  margin-bottom: 0;
  line-height: 30px;
}

.g-bg {
  background: linear-gradient(89.93deg, #42A347 -13.53%, #7B1139 39.43%);
  z-index: -1;
  height: 100%;
  top: 0;
}

@media (min-width: 992px) {
  .g-bg {
    height: calc(100% - 180px);
    top: 90px;
  }
}

.winner-gender {
  width: 56px;
  height: 56px;
}

.carousel-item {
  min-height: 100vh;
}

.carousel-item .age-label {
  background: #5ACA60;
}

.carousel-item-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  max-width: 100%;
  z-index: -1;
}

.carousel-item-bg.story {
  background-image: url(../assets/img/slider/story-bg.jpg);
}

.carousel-item-bg.art {
  background-image: url(../assets/img/slider/art-bg.jpg);
}

.carousel-item-bg.music {
  background-image: url(../assets/img/slider/music-bg.jpg);
}

.carousel-item-bg.sing {
  background-image: url(../assets/img/slider/sing-bg.jpg);
}

.carousel-item-bg.development {
  background-image: url(../assets/img/slider/development-bg.jpg);
}

.carousel-item-bg.science {
  background-image: url(../assets/img/slider/science-bg.jpg);
}

.facbook {
  direction: ltr;
}

.facbook span {
  color: #1877F2;
}

.main-artical-bg {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.8)));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.main-artical-img {
  min-width: 100%;
  min-height: 100%;
  max-width: none;
}

.thumb-img {
  width: 89px;
  min-width: 89px;
  height: 89px;
}

.thumb-img img {
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.book-thumb-img {
  min-width: 49px;
  height: 74.63px;
}

.book-thumb-img img {
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.hero-text {
  margin-top: -3rem;
}

.hero-text li a {
  font-weight: 500;
}

@media (min-width: 992px) {
  .cat-hero img {
    position: relative;
    left: -50px;
  }
}

.cat-link-aside {
  max-width: 300px;
}

.cat-link-aside i {
  line-height: 0.7;
}

.cat-link-aside h3 {
  line-height: 1.5;
}

.cat-link-aside .active {
  background: -webkit-gradient(linear, right top, left top, from(#7B1139), to(#5ACA60));
  background: linear-gradient(270deg, #7B1139 0%, #5ACA60 100%);
  padding: 2.5px;
  border-radius: 1.25rem;
}

.cat-link-aside .active a {
  border-width: 0;
}

.cat-link-aside .active a i {
  -webkit-background-clip: unset;
  background: transparent;
  -webkit-text-fill-color: #971B49;
}

.cat-img-style {
  max-width: 103px;
  width: 103px;
  -webkit-filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.1));
}

.cat-img-style img {
  width: 100%;
}

.age-label {
  background: rgba(90, 202, 96, 0.3);
  border-radius: 10px;
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
  line-height: 1;
}

.help-links-aside i {
  background: -webkit-gradient(linear, left top, left bottom, from(#42a347), to(#7b1139));
  background: linear-gradient(180deg, #42a347 0%, #7b1139 100%);
  line-height: 1.5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 0.7;
}

.help-links-aside i.icon-calender {
  font-size: 40px;
  height: 40px;
}

.help-links-aside i.icon-conditions {
  font-size: 50px;
  height: 50px;
}

.help-links-aside i.icon-faq {
  font-size: 45px;
  height: 45px;
}

.help-links-aside i.icon-prize {
  font-size: 50px;
  height: 50px;
}

.help-links-aside li:not(:first-child):hover {
  background-color: #fff;
}

@media (min-width: 992px) {
  .help-links-aside > ul {
    max-width: 263px;
  }
}

.scroll-to {
  top: -70px;
}

.accordion-button.collapsed {
  font-weight: 500;
}

.main-cat .icon-arrow-left {
  height: 7px;
  font-size: calc(1.3125rem + 0.75vw);
  display: inline-block;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .main-cat .icon-arrow-left {
    font-size: 1.875rem;
  }
}

.main-cat .icon-arrow-left::before {
  position: relative;
  top: -11px;
}

.divider {
  border-top: 4px solid #edf5f6;
}

.img-type {
  width: 72px;
  height: 72px;
}

.remove-selected {
  opacity: 1;
  top: -4px;
  left: -4px;
}

.file-name {
  max-width: 120px;
}

.doc-type {
  height: 72px;
}

.doc-type i {
  height: 32px;
}

.doc-type i::before {
  position: relative;
  top: -2px;
}

.little-innovator-img > div {
  max-width: calc(112px + 0.5rem);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  width: 25%;
}

.little-innovator-img > div img {
  -webkit-filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.1));
}

@media (min-width: 992px) {
  .little-innovator-img > div img {
    min-width: 112px;
  }
}
/*# sourceMappingURL=style.css.map */